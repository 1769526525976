const ValuesData = [
  {
    title: "Humans, not resources",
    icon: "humans",
    description:
      "We are human beings, not numbers. Our design and development process is base on this premise.",
  },
  {
    title: "Passion",
    icon: "passion",
    description:
      "Create unique digital experiences and products is our passion, and we work with passionate people. Attitude in life is everything ",
  },
  {
    title: "Be Nice & lead the way",
    icon: "happy",
    description: `As a business and as individuals, kindness is essential. We grow by helping and teaching others by example. There's no room for smart asses.`,
  },
  {
    title: "Honesty and ownership",
    icon: "honesty",
    description: `We don't believe in saying yes to everything to get more clients. We take ownership of your problems, and we fix them like they're ours.`,
  },
  {
    title: "Expertise",
    icon: "expert",
    description: `At OGD42, we don't stop learning. Learning is a never-ending process, where we become more experts on every iteration.`,
  },
]

export default ValuesData
