import React from "react"
import styled from "styled-components"
// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import BigTitle from "../components/titles"
import { PrimaryTheme } from "../constants/theme"
import ValueTile from "../components/value_tile"
import ValuesData from "../constants/values_data"

const AboutUsPage = () => (
  <Layout>
    <Seo title="OGD42 - About Us - Experts in building Software for Real Estate" />
    <div className="container">
      <div
        className="row justify-content-center"
        style={{ paddingTop: "3rem" }}
      >
        <div
          className="col-sm-12 col-md-12 col-lg-8 col-xl-8 d-flex "
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BigTitle
            data-aos={"fade-up"}
            data-aos-delay={200}
            data-aos-anchor-placement="center-center"
          >
            About Us!
          </BigTitle>
          <StyledParagraph data-aos={"fade-up"} data-aos-delay={400}>
            OGD42 is a US-based software crafting agency specialized in real
            estate software and mobile apps. We merge the best Software
            Engineering frameworks with a fast-paced environment that focuses on
            delivering beautiful apps and experiences to the users.
          </StyledParagraph>
          <StyledParagraph data-aos={"fade-up"} data-aos-delay={400}>
            Our mission is to create unique digital products to empower people
            who use them and bring the maximum quality level.
          </StyledParagraph>
          <StyledParagraph data-aos={"fade-up"} data-aos-delay={400}>
            We have a distributed team, with people in New York, San Diego,
            London, Spain, and Mexico City.
          </StyledParagraph>
        </div>
      </div>
      <div
        className="row justify-content-center"
        style={{ paddingTop: "3rem" }}
      >
        <div
          className="col-sm-12 col-md-12 col-lg-8 col-xl-8 d-flex "
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BigTitle data-aos={"fade-up"} data-aos-delay={200}>
            Values
          </BigTitle>
          <StyledValuesList>
            {ValuesData.map((item, index) => (
              <ValueTile key={`VTK${index}`} {...item} index={index} />
            ))}
          </StyledValuesList>
        </div>
      </div>
    </div>
  </Layout>
)

const StyledParagraph = styled.p`
  width: 80%;
  color: ${PrimaryTheme.text};
  font-size: 1.4rem;
  letter-spacing: 0.2rem;
  line-height: 1.4;
  font-weight: 500;
  text-align: ${props => (props.justify ? "justify" : "left")};
`

const StyledValuesList = styled.div`
  display: flex;
  flex-grow: grow;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export default AboutUsPage
