import React from "react"
import styled from "styled-components"
import {
  FaSmileBeam,
  FaHeart,
  FaUsers,
  FaBrain,
  FaHandshake,
} from "react-icons/fa"
import { SecondaryTheme } from "../constants/theme"

export default function ValueTile({ title, icon, description, index }) {
  return (
    <StyledValueTile
      data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
      data-aos-delay={100}
      data-aos-anchor-placement="bottom-bottom"
    >
      {icon === "humans" && (
        <FaUsers
          color={SecondaryTheme.primary}
          size={50}
          className="valueIcon"
        />
      )}
      {icon === "passion" && (
        <FaHeart
          color={SecondaryTheme.primary}
          size={50}
          className="valueIcon"
        />
      )}
      {icon === "happy" && (
        <FaSmileBeam
          color={SecondaryTheme.primary}
          size={50}
          className="valueIcon"
        />
      )}
      {icon === "honesty" && (
        <FaBrain
          color={SecondaryTheme.primary}
          size={50}
          className="valueIcon"
        />
      )}
      {icon === "expert" && (
        <FaHandshake
          color={SecondaryTheme.primary}
          size={50}
          className="valueIcon"
        />
      )}
      <h2>{title}</h2>
      <p>{description}</p>
    </StyledValueTile>
  )
}

const StyledValueTile = styled.div`
  display: flex;
  width: 45%;
  background-color: rgba(255, 255, 255);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px 20px 20px;
  border-radius: 20px;
  margin-bottom: 40px;

  @media (max-width: 1025px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }

  & h2 {
    color: ${SecondaryTheme.accent};
    font-size: 2.4rem;
    letter-spacing: 0.2rem;
    width: 80%;
    text-align: center;
  }

  & p {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    color: ${SecondaryTheme.accent};
    text-align: center;
    width: 80%;
  }

  @keyframes kaboom {
    90% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }

  @keyframes appear {
    from {
      path-opacity: 0;
    }
    to {
      path-opacity: 1;
    }
  }

  svg {
    animation: kaboom 1.2s ease alternate infinite;
    path {
      animation: color 1s fade-in;
    }
  }

  svg:hover path {
    color: ${SecondaryTheme.primary};
  }
  svg:hover {
    border-bottom-color: ${SecondaryTheme.primary};
    border-bottom-style: solid;
    border-bottom-width: 2px;
    padding-bottom: 10px;
  }
`
